import React, { useEffect, useState } from 'react';
import Alert from "react-bootstrap/Alert";
import PandaDocIFrame from '../Components/PandaDocIFrame';

function PandaDocHolder({ quoteId }) {

    const [pageError, setPageError] = useState(false);
    const [tokens, setTokens] = useState(null);
    const [itemLines, setItemLines] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [unitNumber, setUnitNumber] = useState(null);

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        if (!quoteId || quoteId === undefined) {
            setPageError('The app cannot proceed without a quote number.');
        }
        else {
            setPageError(false);
            const fetchData = async () => {
                let so = null;
                let rq = null;
                let quote = null;
                let quoteLines = null;
                if (quoteId.startsWith("Q")) {
                    rq = await callForQuote(quoteId);
                    quote = rq.quotation;
                    quoteLines = rq.quotationLines;
                }
                if (quoteId.startsWith("SO")) {
                    so = await callForSalesOrder(quoteId);
                }
                let cust = null;
                let contact = null;
                let iLs = [];
                let salesOrder = null;
                let salesOrderLines = null;
                let location = null;
                        
                if (quote||so) {
                    if ((quote && quote.generatedSalesOrderNumber && quote.generatedSalesOrderNumber !== undefined && quote.generatedSalesOrderNumber !== "") || so) {
                        if (!so) {
                            so = await callForSalesOrder(quote.generatedSalesOrderNumber);
                        }
                        salesOrder = so.so;
                        salesOrderLines = so.soLines;
                        cust = await callForCustomer(salesOrder.orderingCustomerAccountNumber);
                        contact = await callForContact(salesOrder.contactPersonId);
                    }
                    if (cust === null) {
                        cust = await callForCustomer(quote.requestingCustomerAccountNumber);
                        contact = await callForContact(quote.contactPersonId);
                    }
                    setRecs(salesOrder, cust, quote, contact);
                    if (salesOrder && salesOrder !== undefined) {
                        iLs = await goThroughSalesLines(salesOrderLines, salesOrder);
                        location = salesOrderLines[0].inventSiteId;
                    }
                    else {
                        iLs = await goThroughQuoteLines(quoteLines, cust);
                        location = quoteLines[0].shippingSiteId;
                    }

                    setItemLines(iLs);
                }
                let equipmentLine = iLs.filter(line => line.isEquipLine === true)[0];
                setTokenInfo(cust, quote, salesOrder, location, equipmentLine, contact);
            }
            fetchData();
        }
    //I don't love doing this, but since we're working with a third party system that doesn't always work as expected this is the way
    // eslint-disable-next-line
    }, [quoteId]);

    const goThroughQuoteLines = async (quoteLines, cust) => {
        let iLs = [];
        for (const element of quoteLines) {
            let currentLine = null;
            if (!element.itemNumber.startsWith("#")) {
                let equip = await callForEquipment(element.ptrInventSerialId, element.itemNumber);
                setUnitNumber(element.inventSerialId);
                currentLine = setEquipLine(equip, element.salesPriceQuantity, element.lrtTimeUnitPrice, element.lrtTariffUnitId);
                currentLine.isEquipLine = true;
            }
            else {
                currentLine = setNonEquipLineQuote(element);
            }
            iLs.push(currentLine);
        }
        return iLs;
    }

    const goThroughSalesLines = async (salesOrderLines, salesOrder) => {
        let iLs = [];
        let chargeLine = salesOrderLines.find((line) => {
            return line.itemid === "#GH";
        });
        for (const element of salesOrderLines) {
            let currentLine = null;
            if (!element.itemid.startsWith("#")) {

                let equip = await callForEquipment(element.inventSerialId, element.itemid);
                setUnitNumber(element.inventSerialId);
                if (chargeLine) {
                    //sales order is best
                    currentLine = setEquipLine(equip, element.salesqty, chargeLine.lrtTimeUnitPrice, salesOrder.lrtTariffUnitId);
                    currentLine.isEquipLine = true;
                }
                else {
                    currentLine = setEquipLine(equip, element.salesqty, element.lrtTimeUnitPrice, salesOrder.lrtTariffUnitId);
                    currentLine.isEquipLine = true;
                }

            }
            else {
                if (element.itemid !== "#GH") {
                    currentLine = setNonEquipLineSO(element, chargeLine, salesOrder.lrtTariffUnitId);
                }
            }
            if (currentLine !== null) {
                iLs.push(currentLine);
            }
        }
        return iLs;
    }
    const getTariffRate = (tariffRate = "MO") =>{
        let perDiemString = "";
        if (tariffRate === "MO") { perDiemString = " Per Month"; }
        else if (tariffRate === "W") { perDiemString = " Per Week"; }
        else { perDiemString = " Per 28 Days"}
        return perDiemString;
    }

    const setEquipLine = (equip, qty, price, tariffRate = "MO") => {
        let perDiemString = getTariffRate(tariffRate);
        let currentLine = {
            sku: equip.inventEquipmentId,
            custom_fields: {
                unitNo: equip.inventEquipmentId,
                config: equip.ptrFutureConfig !== "" ? equip.ptrFutureConfig : equip.ptrDisplayConfigId,
                qty: qty,
                desc: `${equip.ptrItemYear} ${equip.lrtManufactureId} ${equip.lrtManufactureItemId} - ${equip.ptrTrim} - ${equip.ptrFuelType}`,
                make: equip.lrtManufactureId,
                model: equip.lrtManufactureItemId,
                year: equip.ptrItemYear,
                value: USDollar.format(equip.ptrEquipmentValue),
                mileage: equip.operatingUnitValue1,
                fuel: equip.ptrFuelType,
                vin: equip.lrtManufactureInventSerialId,
                plate: equip.ptrLicensePlate,
                plateExpiration: equip.ptrPlateExpirationDateModified,
                charge: USDollar.format(price) + perDiemString
            }
        };

        return currentLine;
    }

    const setNonEquipLineQuote = (line) => {
        let currentLine;

        let perDiemString = getTariffRate(line.lrtTariffUnitId);
        if (line.lrtTimeUnitPrice > 0) {
            currentLine = {
                sku: line.itemid,
                custom_fields: {
                    qty: line.requestedSalesQuantity, desc: line.lineDescription, charge: USDollar.format((line.lrtTimeUnitPrice).toFixed(2)) + perDiemString
                }
            };
        }
        else {
            currentLine = {
                sku: line.itemid,
                custom_fields: {
                    qty: line.requestedSalesQuantity, desc: line.lineDescription, charge: line.salesPrice > 0 ? USDollar.format((line.salesPrice).toFixed(2)) : ""
                }
            };
        }

        return currentLine;
    }

    const setNonEquipLineSO = (line, chargeLine, tariffRate = "MO") => {
        let perDiemString = getTariffRate(tariffRate);
        let currentLine;
        if (line.lrtTimeUnitPrice > 0) {
            currentLine = {
                sku: line.itemid,
                custom_fields: {
                    qty: line.salesqty, desc: line.lineDescription, charge: !chargeLine ? USDollar.format((line.lrtTimeUnitPrice).toFixed(2)) + perDiemString : ""
                }
            };
        }
        else {
            currentLine = {
                sku: line.itemid,
                custom_fields: {
                    qty: line.salesqty, desc: line.lineDescription, charge: line.salesprice > 0 ? USDollar.format((line.salesprice).toFixed(2)): ""
                }
            };
        }
        
        return currentLine;
    }

    const setRecs = (so, cust, quote, contact) => {
        let recipSet = [
            {
                first_name: so ? so.firstName : cust.personFirstName,
                last_name: so ? so.lastName : cust.personLastName,
                email: contact ? contact.primaryEmailAddress : so.email,
                phone: contact ? contact.primaryPhoneNumber : so.phone,
                roleName: process.env.REACT_APP_CLIENT_ROLE_NAME
            },
            {
                first_name: process.env.REACT_APP_CONTACT_FIRST,
                last_name: process.env.REACT_APP_CONTACT_LAST,
                email: process.env.REACT_APP_EMAIL,
                roleName: process.env.REACT_APP_ROLE_NAME
            }
        ];
        setRecipients(recipSet);
    }

    const setTokenInfo = (customer, quote, so, shippingSite, equip, contact) => {
        let tewdey = new Date();
        let weeklyAllowance = 625;
        let monthlyAllowance = 2500;
        let mileageOverage = "$.30/mile";
        let perDiemString = "";
        let contactName = "";
        
        if (so?.ptrAllowanceCharge) {
            mileageOverage = so?.ptrAllowanceCharge;
        }
        if (so?.ptrMileageAllowance) {
            monthlyAllowance = parseInt(so.ptrMileageAllowance.replace(/,/g, ''));
            weeklyAllowance = monthlyAllowance / 4;
        }
        if (so?.lrtTariffUnitId) {
            perDiemString = getTariffRate(so.lrtTariffUnitId);
        }
        else if (customer?.lrtTariffUnitId) {
            perDiemString = getTariffRate(customer.lrtTariffUnitId);
        }
        if (so?.firstName && so?.lastName) {
            contactName = so?.firstName + " " + so?.lastName;
        }
        else {
            contactName = contact.contactPersonName;
        }

        let token = {
            LIName: customer?.organizationName ?? "",
            LIAddress: customer?.addressStreet ?? "",
            LICity: customer?.addressCity ?? "",
            LIState: customer?.addressState ?? "",
            LIZip: customer?.addressZipCode ?? "",
            LRContactName: contactName,
            LRPhone: contact ? contact.primaryPhoneNumber : so?.phone ?? "",
            LREmail: contact ? contact.primaryEmailAddress : so?.email ?? "",
            LRCustomerPO: so? so.customersOrderReference : quote?.customersReference ?? "",
            LRAPContact: '',//lesee rep ap contact -- not on original
            LRAPEmail: '',//lesee rep ap email -- not on original
            LRAPContactFull: '', //lesee rep ap -- not on original
            BAAddress: '',
            BACity: '',
            BAState: '',
            BAZip: '',
            DIContactName: so ? so?.ptrPickupContact : quote?.ptrPickupContact ?? "",
            DIAddress: so ? so.deliveryAddressStreet : quote?.deliveryAddressStreet ?? "",
            DICity: so ? so.deliveryAddressCity : quote?.deliveryAddressCity ?? "",
            DIState: so ? so.deliveryAddressStateId : quote?.deliveryAddressStateId ?? "",
            DIZip: so ? so.deliveryAddressZipCode : quote?.deliveryAddressZipCode ?? "",
            DIFOB: shippingSite ?? "",
            DIFreightMethod: so? so.deliveryModeCode : quote?.deliveryModeCode ?? "",
            DIFreightCharge: so? so.ptrDeliveryChargeStr : quote?.ptrDeliveryChargeStr ?? "",
            DIReturnFOB: '', // -- not on original
            DIFreightReturnFee: so?.ptrReturnChargeStr,
            CITermOfContract: '',
            CIStartDate: so ? so.formattedLRTRentalFromDateTime : quote.formattedLRTRentalFromDateTime,
            CIRentalTermRate: perDiemString,
            SpecialTerms: so?.ptrSpecialTerms ?? quote?.ptrSpecialTerms,
            docName: so?.lrtSubjectMatter ?? quote?.lrtSubjectMatter,
            MileageAllowance: monthlyAllowance,
            MileageOverageCharge: mileageOverage,
            EquipmentMake: equip?.custom_fields?.make,
            EquipmentModel: equip?.custom_fields?.model,
            EquipmentYear: equip?.custom_fields?.year,
            EquipmentMMY: `${equip?.custom_fields?.year} ${equip?.custom_fields?.make} ${equip?.custom_fields?.model}`,
            EquipmentMakeModelYear: `${equip?.custom_fields?.make} ${equip?.custom_fields?.model} ${equip?.custom_fields?.year} `,
            EquipmentFuel: equip?.custom_fields?.fuel,
            EquipNumber: equip?.custom_fields?.unitNo,
            EquipCharge: equip?.custom_fields?.charge,
            VIN: equip?.custom_fields?.vin,
            EquipmentValue: equip?.custom_fields?.value,
            EquipmentMileage: equip?.custom_fields?.mileage,
            SignerName: (so ? so.firstName : customer.personFirstName) + " " + (so ? so.lastName : customer.personLastName),
            Today: `${(tewdey.getMonth() + 1)}-${tewdey.getDay()}-${tewdey.getFullYear()}`,
            MileageAllowanceString: `${weeklyAllowance} miles per week or ${monthlyAllowance}`,
            PerDiemString: perDiemString,
            Plate: equip?.custom_fields?.plate + " / IN",
            PlateExp: equip?.custom_fields?.plateExpiration ?? "",
            Config: equip?.custom_fields?.config ?? "",
            SalesGroup: so ? so.commissionSalesRepresentativeGroupId : "", //only applies to Sales Orders
        };
        setTokens(token);
    }

    const callForCustomer = async (custId) => {
        return await callApi(process.env.REACT_APP_CUSTOMER_API.replace('{id}', custId));
    }
    const callForContact = async (contactId) => {
        return await callApi(process.env.REACT_APP_CONTACT_API.replace('{id}', contactId));
    }
    const callForEquipment = async (unitNumber, itemNumber) => {
        return await callApi(process.env.REACT_APP_EQUIPMENT_API.replace('{unitNumber}', unitNumber).replace('{itemNumber}', itemNumber));
    }
    const callForQuote = async (qId) => {
        return await callApi(process.env.REACT_APP_QUOTE_API.replace('{id}', qId));
    }
    const callForOpprotunityLines = async (qId, itemNumber) => {
        return await callApi(process.env.REACT_APP_OPPROTUNITY_LINES_API.replace('{id}', qId).replace('{itemNumber}', itemNumber));
    }
    const callForSalesOrder = async (id) => {
        return await callApi(process.env.REACT_APP_SALES_ORDER_API.replace('{salesOrderId}', id));
    }
    const callToAttachDocuments = async (unitNumber, docId) => {
        await fetch(process.env.REACT_APP_ATTACH_DOC_API.replace('{unitNumber}', unitNumber).replace('{docId}', docId));
        await fetch(process.env.REACT_APP_ATTACH_DOT_API.replace('{unitNumber}', unitNumber).replace('{docId}', docId));
    }

    function callApi(uriString) {
        return fetch(uriString)
            .then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    setPageError(error);
                    return;
                }
            );
    }


    return (
        <div>
            {
                pageError &&
                <Alert variant="danger" >
                    <Alert.Heading>
                        {pageError}
                    </Alert.Heading>
                </Alert>
            }
            <PandaDocIFrame tokens={tokens} recipients={recipients} itemLines={itemLines} callToAttachDocuments={callToAttachDocuments} unitNumber={unitNumber}  ></PandaDocIFrame>
        </div>
    );
}

export default PandaDocHolder;